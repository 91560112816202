import Swiper, { Navigation, Pagination, Parallax, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Parallax, Autoplay]);
const swiper = new Swiper('.practice__swiper', {
  parallax: true,
  speed: 700,
  loop: true,
  watchSlidesVisibility: true,
  navigation: {
    prevEl: '.swiper-button-prev',
    nextEl: '.swiper-button-next',
  },
  breakpoints: {
    320: {
      slidesPerView: 1.2,
      spaceBetween: 10
    //   parallax: true
    },
    480: {
      slidesPerView: 2.3,
      spaceBetween: 20,
      parallax: false
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 30,
      parallax: false
    },
    1024: {
        slidesPerView: 3,
        spaceBetween: 60,
        parallax: false
      }
  }
});

const swiperOther = new Swiper('.practice__swiper-other', {
      speed: 700,
      loop: true,
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false
      },
      parallax: true,
      breakpoints: {
        320: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        //   parallax: true,
        },
        480: {
          slidesPerView: 2.3,
          spaceBetween: 20,
          parallax: false,
        },
        640: {
          slidesPerView: 3,
          spaceBetween: 30,
          parallax: false,
        },
        1024: {
            slidesPerView: 4,
            spaceBetween: 40,
            parallax: false,
          }
      }
    });
