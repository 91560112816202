//console.log('footer');
const emailText = document.querySelector('.text-stroke');
const indicator = document.querySelector('.footer__offer-indicator');

let options = {
  root: null, // Относительно вьюпорта
  rootMargin: '0px',
  threshold: .5 // Когда 5% элемента пересекают область просмотра
};

// Функция обратного вызова
let callback = (entries, observer) => {
  entries.forEach(entry => {
    if(entry.isIntersecting) {
      // Что должно произойти, когда элемент появляется в области просмотра
      emailText.style.animation = 'textStrokeAnim 5s linear both';
      setTimeout(() => {
        indicator.style.opacity = '1';
        indicator.style.transform = 'translateY(-10%)';
    }, 5000)
    } else {
      emailText.style.animation = 'none';
      indicator.style.opacity = '0';
      indicator.style.transform = 'translateY(40%)';
    }
  });
};

// Создание наблюдателя Intersection Observer
// let observer = new IntersectionObserver(callback, options);

// Начало отслеживания элемента
// observer.observe(emailText);


//setTimeout(() => {
//    indicator.style.opacity = '1';
//}, 5000)
