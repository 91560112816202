function $qs(param) {
    return document.querySelector(param);
}

function $qsAll(params) {
    return document.querySelectorAll(params);
}

// реализация кнопки "показать больше"

let btnMore = $qsAll('.btn-more');

btnMore.forEach((el, index) => {
    if (index < 2) {
        el.addEventListener('click', (ev) => {
            let parent = ev.target.parentNode.parentNode; //переменная для прародителя
            el.style.display = 'none';
            // parent.style.transition = 'max-height 0.3s ease'
            parent.style.maxHeight = 'fit-content'; //находим прародителя
        })
    }
});

setTimeout(function () {
    $qs('.callBackButton').style.opacity = '1';
}, 15000);


