
//if (window.location.pathname.includes('about.html')) {
//if (window.location.pathname === '/about.html') {
    function getDuration(startDay, endDay) {
        let startParts = startDay.split(' '),
            startMonth = monthDict[startParts[0]],
            startYear = parseInt(startParts[1])

        let endParts = endDay.split(' '),
            endMonth = monthDict[endParts[0]],
            endYear = parseInt(endParts[1]);

        let start = new Date(startYear, startMonth),
            end = new Date(endYear, endMonth);

        let years = end.getFullYear() - start.getFullYear(),
            months = end.getMonth() - start.getMonth();
            //months = (years * 12) + (endMonth - startMonth);

        if (months < 0) {
            years --;
            months = months + 12;
        }

        let duration = '';

        // Вычисление правильного спряжения для лет

        if (years === 1) {
          duration += '1 год';
        } else if (years > 1 && years < 5) {
          duration += `${years} года`;
        } else if (years >= 5) {
          duration += `${years} лет`;
        }

        // Добавление разделителя, если есть и месяцы
        if (years && months) {
          duration += ' и ';
        }

        // Вычисление правильного спряжения для месяцев
        if (months > 0) {
            if (months === 1) {
                duration += '1 месяц';
              } else if (months > 1 && months < 5) {
                duration += `${months} месяца`;
              } else if (months >= 5 || months === 0) {
                duration += `${months} месяцев`;
              }
        }

        return duration;
      }

      const monthDict = {
        'янв': 0,
        'фев': 1,
        'мар': 2,
        'апр': 3,
        'май': 4,
        'июн': 5,
        'июл': 6,
        'авг': 7,
        'сен': 8,
        'окт': 9,
        'ноя': 10,
        'дек': 11
      };

    //  const duration = getDuration(startDay, endDay);

    let items = document.querySelectorAll('.exp__list-item');

    for (const item of items) {
        let startDate = item.querySelector('.startDate'),
            endDate = item.querySelector('.endDate'),
            durationDate = item.querySelector('.durationDate')


        if (startDate && endDate && durationDate) {
            let startDay = startDate.textContent,
                endDay = endDate.textContent,
                months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];

            if (endDay === 'настоящее') {
                let today = new Date();
                let month = today.getMonth();
                let year = today.getFullYear();
                endDay = `${months[month]} ${year}`;
            }

            let duration = getDuration(startDay, endDay);

            durationDate.textContent = duration;
          }
    }

    let durationDate = document.querySelectorAll('.durationDate'),
        totalYears = 0,
        totalMonths = 0

    for (const el of durationDate) {
        let durationText = el.textContent;
        //let [yearsString, monthsString] = durationText.split(' и ')
        let matches = durationText.match(/(\d+)\s*(год|года|лет)?\s*(и\s*(\d+)\s*(месяц|месяцев)?)/);

        if (matches) {
            let years = parseInt(matches[1]);
            let months = parseInt(matches[4]);
            if (years) {
                totalYears += years;
              }

              if (months) {
                totalMonths += months;
              }
            }
          }

    if (totalMonths >= 12) {
        let excessYears = Math.floor(totalMonths / 12),
            remainingMonths = totalMonths % 12

        totalYears += excessYears;
        totalMonths = remainingMonths;
    }

    //console.log(totalYears);
    //console.log(totalMonths);

    const indicatorExp = document.querySelector('.indicator__txt-exp');
    const durationTotal = document.querySelector('.exp__title-durationTotal');

    let yearsText = `${totalYears} ${declension(totalYears, ['год', 'года', 'лет'])}`;
    let monthsText = totalMonths > 0 ? `${totalMonths} ${declension(totalMonths, ['месяц', 'месяца', 'месяцев'])}` : '';

    indicatorExp.textContent = monthsText ? `${yearsText} и ${monthsText}` : yearsText;
    durationTotal.textContent = monthsText ? `${yearsText} и ${monthsText}` : yearsText;

    function declension(number, titles) {
       let cases = [2, 0, 1, 1, 1, 2];
       return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }
    //durationTotal.textContent = `${totalYears} лет и ${totalMonths} месяцев`

//}
