//console.log('header');
import { getHeaderHeight } from '../functions/header-height';

const header = document.querySelector('.header');

window.addEventListener('scroll', () => {
    if (window.scrollY > 120) {
        header.style.backdropFilter = 'blur(5px)';
    } else {
        header.style.backdropFilter = 'blur(0px)';
    }
});

getHeaderHeight();

