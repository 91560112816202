// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, top} from '@popperjs/core';
// createPopper('.callBack_btn', '#callBack', {
//   placement: 'top'
// });

// Подключение свайпера
// import Swiper, { Navigation, Pagination } from 'swiper';
// Swiper.use([Navigation, Pagination]);
// const swiper = new Swiper(el, {
//   slidesPerView: 'auto',
// });

// Подключение анимаций по скроллу
 import AOS from 'aos';
 AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
 import SmoothScroll from 'smooth-scroll';
 const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

 import { validateForms } from './functions/validate-forms';
 const rules1 = [
    {
        ruleSelector: '#inputName',
        rules: [
            {
                rule: 'minLength',
                value: 3,
                errorMessage: 'Не менее 3 символов'
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Заполните имя!'
            }
        ]
    },
    {
        ruleSelector: '#inputTel',
        //tel: true,
        telError: 'Введите корректный номер телефона!',
        rules: [
            {
                rule: 'minLength',
                value: 10,
                errorMessage: 'Заполните номер телефона полностью'
            }
        ]
    },
    {
        ruleSelector: '#inputEmail',
        rules: [
            {
                rule: 'email',
                errorMessage: 'Заполните E-mail полностью'
            }
        ]
    }
 ];

 const rules2 = [
    {
        ruleSelector: '#callBackInputName',
        rules: [
            {
                rule: 'minLength',
                value: 3,
                errorMessage: 'Не менее 3 символов'
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Заполните имя!'
            }
        ]
    },
    {
        ruleSelector: '#callBackInputTel',
        //tel: true,
        // telError: 'Введите корректный номер телефона!',
        rules: [
            {
                rule: 'minLength',
                value: 10,
                errorMessage: 'Заполните номер телефона полностью'
            },
            {
                rule: 'required',
                value: true,
                errorMessage: 'Укажите Ваш контактный телефон!'
            }
        ]
    }
 ];

 const afterForm = () => {
   console.log('Произошла отправка сообщения');
 };

 validateForms('.form__contact', rules1, afterForm);
 validateForms('.form__callBack', rules2, afterForm);


//import IMask from 'imask';
