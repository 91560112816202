
//if (window.location.pathname.includes('index.html')) {
//if (window.location.pathname === '/index.html') {
  let animation = false,
    animDur = 1000,
    row = document.querySelector('.box__row'),
    rows = document.querySelectorAll('.box__row'),
    cells = document.querySelectorAll('.box__row-cell'),
    content = document.querySelectorAll('.box__content'),
    closeBtn = document.querySelector('.box__close');

  function animFalse() {
    animation = false;
  }

  function active() {
    if (animation) return;
    let cellData = this.dataset.cell;
    let targetContent = document.querySelector('.box__content[data-content="' + cellData + '"]');
    animation = true;

    cells.forEach(function(cell) {
      cell.classList.add('cell-fade');
    });
    this.classList.add('active');
    targetContent.classList.add('show-content');
    closeBtn.classList.add('box-close-active');

    setTimeout(animFalse, animDur);
  }

  function close() {
    animation = true;

    cells.forEach(function(cell) {
      cell.classList.remove('active', 'cell-fade');
    });
    content.forEach(function(c) {
      c.classList.remove('show-content');
    });
    closeBtn.classList.remove('box-close-active');

    setTimeout(animFalse, animDur);
  }

//  row.addEventListener('click', function(e) {
//    if (e.target.classList.contains('box__row-cell')) {
//      active.call(e.target);
//    }
//  });

for (const row of rows) {
    row.addEventListener('click', function(e) {
        if (e.target.classList.contains('box__row-cell')) {
          active.call(e.target);
        }
      });
  }


closeBtn.addEventListener('click', close);



document.addEventListener('click', function(e) {

    if (!rows[0].contains(e.target) && !rows[1].contains(e.target)) {
        close();
    }
  });

document.addEventListener('keydown', function(e) {
    if (e.key === 'Escape' && rows[0] && rows[1] && (!rows[0].contains(e.target) && !rows[1].contains(e.target))) {
      close();
    }
  });


  cells.forEach(function(cell) {
    cell.addEventListener('mouseenter', function() {
      cells.forEach(function(c) {
        c.classList.add('hover-cell');
      });
      this.classList.remove('hover-cell');
    });
    cell.addEventListener('mouseleave', function() {
      cells.forEach(function(c) {
        c.classList.remove('hover-cell');
      });
    });
  });

  window.addEventListener('scroll', function() {
    let boxSection = document.querySelector('.box__section'),
        boxSectionRect = boxSection.getBoundingClientRect(),
        boxSectionTop = boxSectionRect.top,
        windowHeight = window.innerHeight;

    if (boxSectionTop <= windowHeight / 2) {
      boxSection.style.boxShadow = '0 0 50px 0 rgba(75, 27, 27, .7)';
    } else {
      boxSection.style.boxShadow = 'none';
    }
  });
//}
