//console.log('form');


//if (window.location.pathname.includes('index.html')) {
//if (window.location.pathname === '/index.html') {

import GraphModal from 'graph-modal';



let contactForm = document.querySelector('.form__contact'),
    inputName = document.querySelector('#inputName'),
    inputTel = document.querySelector('#inputTel'),
    numberValue = inputTel.value.replace(/\D/g, ''),
    inputEmail = document.querySelector('#inputEmail'),
    labelName = document.querySelector('#labelName'),
    labelTel = document.querySelector('#labelTel'),
    labelEmail = document.querySelector('#labelEmail'),
    formFills = document.querySelectorAll('.form__contact-fill'),
    formBtn = document.querySelector('.form__contact-btn'),
    callBackBtn = document.querySelector('.callBackBtn'),
    callBackLabelName = document.querySelector('#callBackLabelName'),
    callBackInputName = document.querySelector('#callBackInputName'),
    callBackLabelTel = document.querySelector('#callBackLabelTel'),
    callBackInputTel = document.querySelector('#callBackInputTel');

let flag

for (let fill of formFills) {
    let input = fill.querySelector('.form__fill-input'),
        label = fill.querySelector('.form__fill-label'),
        labelContent = label.textContent,
        labelColor = window.getComputedStyle(label).color;

    formBtn.disabled = true;
    formBtn.style.color = '#000'



    //inputTel.addEventListener('focus', () => {
    //    let mask = IMask(inputTel, {
    //        mask: '+{7}(000)000-00-00',
    //        //lazy: false,  // make placeholder always visible
    //        //placeholderChar: '*'     // defaults to '_'
    //    })
    //    //mask.updateValue()

    //inputTel.addEventListener('blur', () => {

    //        if (inputTel.value.length < 10) {
    //            inputTel.value = '';
    //            labelTel.classList.remove('input-fixed', 'minText');
    //            //label.textContent = labelContent;
    //            //label.style.color = labelColor;
    //        }
    //    });
    //});


    input.addEventListener('input', (el) => {
        //let labelContent = label.textContent,
        //    labelColor = window.getComputedStyle(label).color;
        if (inputName.value !== '' && inputTel.value !== '' || inputEmail.value !== '') {
            formBtn.style.cursor = 'pointer',
                formBtn.disabled = false,
                formBtn.style.backgroundColor = '#1C008A',
                formBtn.style.color = '#fff'
        } else {
            formBtn.style.cursor = 'not-allowed',
                formBtn.disabled = true,
                formBtn.style.backgroundColor = '#a6a6a6',
                formBtn.style.color = '#000'
        };

        if (callBackInputName.value !== '' && flag && callBackInputTel.value.replace(/\D/g, '').length === 11) {
            callBackBtn.style.cursor = 'pointer';
            callBackBtn.disabled = false;
            callBackBtn.style.backgroundColor = '#1C008A';
            callBackBtn.style.color = '#fff';
        } else {
            callBackBtn.style.cursor = 'not-allowed';
            callBackBtn.disabled = true;
            callBackBtn.style.backgroundColor = '#a6a6a6';
            callBackBtn.style.color = '#000';
        };

        if (input.value.trim() !== '') {
            label.classList.add('input-fixed', 'minText');
        } else {
            label.classList.remove('input-fixed', 'minText');
        };

        if (/\d/.test(inputName.value) || /[!@#$%^&*(),.?":{}|<>]/.test(inputName.value)) {
            labelName.textContent = 'Имя не может содержать цифры и спец.символы'
        } else {
            label.textContent = labelContent;
        }

        if (/\d/.test(callBackInputName.value) || /[!@#$%^&*(),.?":{}|<>]/.test(callBackInputName.value)) {
            callBackLabelName.textContent = 'Только буквы!'
            flag = false
        } else {
            callBackLabelName.textContent = 'Ваше имя';
            flag = true;
        };

        //if (!/^\w+@\w+\.\w{2,3}$/.test(inputEmail.value)) {
        //    labelEmail.textContent = 'Введите в формате example@mail.com; не более 3 символов после точки';
        //    label.style.color = '#AD0000'
        //} else {
        //    label.textContent = labelContent;
        //    label.style.color = labelColor;
        //}

    });


    contactForm.addEventListener('submit', (ev) => {
        ev.preventDefault();

        label.classList.remove('input-fixed', 'minText');
        formBtn.style.cursor = 'not-allowed',
            formBtn.disabled = true,
            formBtn.style.backgroundColor = '#a6a6a6',
            formBtn.style.color = '#000'
    });

    document.querySelector('.form__callBack').addEventListener('submit', (ev) => {
        ev.preventDefault();

        callBackLabelName.classList.remove('input-fixed', 'minText');
        callBackLabelTel.classList.remove('input-fixed', 'minText');
        callBackLabelName.textContent = labelContent;
        callBackBtn.style.cursor = 'not-allowed',
            callBackBtn.disabled = true,
            callBackBtn.style.backgroundColor = '#a6a6a6',
            callBackBtn.style.color = '#000'
    });
};

// открытие модального окна формы Обратного звонка по клику на кнопку сайте

function modalOpen (param) {
    const modal = new GraphModal();
    modal.open(param);
}

const callBackButton = document.querySelector('.callBackButton');
const menuCallBackButton = document.querySelector('.menu__call-back');
const heroCallBackButton = document.querySelector('.hero__call-back');
const priceCallBackButton = document.querySelector('.price__btn');

callBackButton.addEventListener('click', e => {
    e.preventDefault();
    modalOpen('callBack');
});

// открытие модального окна формы Обратного звонка по клику в открывшемся окне меню

menuCallBackButton.addEventListener ('click', e => {
    e.preventDefault();
    modalOpen('callBack');
})

heroCallBackButton.addEventListener('click', e => {
    e.preventDefault();
    modalOpen('callBack');
});

priceCallBackButton.addEventListener('click', e => {
    e.preventDefault();
    modalOpen('callBack');
});
