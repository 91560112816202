import JustValidate from 'just-validate';
import Inputmask from "inputmask";
import GraphModal from 'graph-modal';


export const validateForms = (selector, rules, afterSend) => {
    const form = document?.querySelector(selector);
    const telSelector = form?.querySelector('input[type="tel"]');

    if (!form) {
        console.error('Нет такого селектора!');
        return false;
    }

    if (!rules) {
        console.error('Вы не передали правила валидации!');
        return false;
    }

    if (telSelector) {
        const inputMask = new Inputmask('+7 (999) 999-99-99');
        inputMask.mask(telSelector);

        for (let item of rules) {
            if (item.tel) {
                item.rules.push({
                    rule: 'function',
                    validator: function () {
                        const phone = telSelector.inputmask.unmaskedvalue();
                        return phone.length === 10;
                    },
                    errorMessage: item.telError
                });
            }
        }
    }

    const validation = new JustValidate(selector);

    for (let item of rules) {
        validation
            .addField(item.ruleSelector, item.rules);
    }

    validation.onSuccess((ev) => {
        let formData = new FormData(ev.target);

        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    if (afterSend) {
                        afterSend();
                    }

                    const modal = new GraphModal();
                    modal.open('contact');
                    console.log('Отправлено');
                }
                if (xhr.status === 404) {

                    console.log('Ошибка 404');
                    alert('Ошибка 404')
                }
            }
        }

        xhr.open('POST', 'mail.php', true);
        xhr.send(formData);

        ev.target.reset();
    })

};
