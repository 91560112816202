//console.log('hero');

//arround worls-----
//if (window.location.pathname.includes('index.html')) {
//    let canvas = document.getElementById('myCanvas'),
//    ctx = canvas.getContext('2d'),
//    image = document.querySelector('.hero__icon-image'),
//    text = 'юридические   услуги   организациям';

//ctx.font = '20px Roboto';
//ctx.textBaseline = 'top';
//ctx.fillStyle = '#a6a6a6';

//let centerX = canvas.width / 2,
//    centerY = canvas.height / 2,
//    radius = 60,
//    angle = Math.PI / 2,
//    rotationSpeed = -Math.PI / 180;

//ctx.clearRect(0, 0, canvas.width, canvas.height);

//function calculateTextWidth(text) {
//  return ctx.measureText(text).width;
//};

//function drawText() {
//  ctx.beginPath();
//  ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI); // Рисуем круглую рамку
//  ctx.stroke();
//  ctx.closePath();

//  ctx.save();
//  ctx.clip(); // Применяем обрезку по форме круга

//  ctx.drawImage(image, centerX - radius, centerY - radius, 2 * radius, 2 * radius); // Отрисовываем изображение внутри рамки
//  ctx.restore();

//  ctx.textAlign = "center";

//  let angleOffset = 0;
//  text.split('').forEach((letter) => {
//    const letterWidth = calculateTextWidth(letter);
//    const letterAngle = angle + angleOffset - Math.PI / 2;
//    const letterX = centerX + Math.cos(letterAngle) * (radius + letterWidth / 2);
//    const letterY = centerY + Math.sin(letterAngle) * (radius + letterWidth / 2);

//    ctx.save();
//    ctx.translate(letterX, letterY);
//    ctx.rotate(angle + angleOffset);
//    ctx.fillText(letter, 0, -15);
//    ctx.restore();

//    angleOffset += letterWidth / radius;
//  });
//}

//function animate() {
//  ctx.clearRect(0, 0, canvas.width, canvas.height);
//  angle += rotationSpeed;
//  drawText();
//  requestAnimationFrame(animate);
//}

//image.onload = function() {
//  animate();
//};

//};



//running line-------
//if (window.location.pathname.includes('/index.html')) {
//if (window.location.pathname === './index.html') {
    let speed = .2; // Скорость перемещения строки (меньшее значение - медленнее)

    window.addEventListener('scroll', function() {
      let firstTitle = document.querySelector('.hero__title-services.first'),
          secondTitle = document.querySelector('.hero__title-services.second'),
          scrollTop = window.pageYOffset || document.documentElement.scrollTop,
          scrollHeight = document.documentElement.scrollHeight,
          windowHeight = window.innerHeight,
          percentageScrolled = (scrollTop / (scrollHeight - windowHeight)) * 100
          firstTranslateX = -percentageScrolled / 100 * (firstTitle.offsetWidth + window.innerWidth),
          secondTranslateX = percentageScrolled / 100 * (secondTitle.offsetWidth + window.innerWidth) - secondTitle.offsetWidth;

      firstTitle.style.transform = 'translateX(' + firstTranslateX * speed + 'px) translateX(-10%)';
      secondTitle.style.transform = 'translateX(' + secondTranslateX * speed + 'px) translateX(20%)';
    });
//};

